export default function ThemeThirdPositionMedal() {
    return (
      <svg height="30" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#FB6767"/>
          <path d="M148 0H0L148 200.5H294L148 0Z" fill="#FD9E9E"/>
          <circle cx="224.5" cy="295.5" r="134.5" fill="#D7961A"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B7872B"/>
      </svg>
    )
  }
  