import { useParams, NavLink, Image, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import RatingsChart from "../components/RatingsChart";
import OverallFirstPositionMedal from "../components/OverallFirstPositionMedal";
import OverallSecondPositionMedal from "../components/OverallSecondPositionMedal";
import OverallThirdPositionMedal from "../components/OverallThirdPositionMedal";
import ThemeFirstPositionMedal from "../components/ThemeFirstPositionMedal";
import ThemeSecondPositionMedal from "../components/ThemeSecondPositionMedal";
import ThemeThirdPositionMedal from "../components/ThemeThirdPositionMedal";

const MinifigurePage = () => {
  const {searchValue, themeUrl} = useParams(); // get the search value (i.e. minifig ID) from the url. The url is specified in the SearchBar component. Also get the themeUrl for use in PHP scripts
  
  const [ratingData, setRatingData] = useState({});
  const [minifigureDetails, setMinifigureDetails] = useState({});
  const [winLossStats, setWinLossStats] = useState({});
  const [position, setPosition] = useState(null);
  const [positionInTheme, setPositionInTheme] = useState(null);
  const [positionChange, setPositionChange] = useState("");
  const [positionChangeInTheme, setPositionChangeInTheme] = useState("");
  const [positionHistory, setPositionHistory] = useState("");
  const [characterMinifigureCount, setCharacterMinifigureCount] = useState("");
  const [ratingsData, setRatingsData] = useState([]); //Set to expect an array because the associated PHP script returns an array of objects
  const [fetchError, setFetchError] = useState(null);

  const minifigureImagePath = "/images/minifigures";

  // Determine the API base URL based on the environment
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
    
  const brickrankerURL = "https://brickranker.com/rankings/minifigures"

  const fetchMaxRating = async () => {
    try {
      const response = await fetch(`${apiUrl}/getMaxRating.php?minifigID=${searchValue}`);
      const data = await response.json();
      setRatingData(data);
    } catch (error) {
      console.error("Error fetching max rating", error);
    }
  };
  
  useEffect(() => {
    fetchMaxRating();
  }, []);

  const fetchMinifigureDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}/getMinifigureDetails.php?minifigID=${searchValue}`);
      const data = await response.json();
  
      if (data.success) {
        setMinifigureDetails(data.data); // Store entire object in state
      } else {
        console.error("Error fetching minifigure details:", data.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  
  useEffect(() => {
    if (searchValue) {
      fetchMinifigureDetails();
    }
  }, [searchValue]);

  useEffect(() => {
    if (minifigureDetails && minifigureDetails.character_name) {
      const fetchCharacterMinifigureCount = async () => {
        try {
          const characterMinifigureCountResponse = await fetch(
            `${apiUrl}/getCountOfCharacterMinifigures.php?characterName=${minifigureDetails.character_name}`
          );
          const characterMinifigureCountData = await characterMinifigureCountResponse.json();
          setCharacterMinifigureCount(characterMinifigureCountData);
        } catch (error) {
          console.error("Error fetching number of minifigures for character:", error);
        }
      };
  
      fetchCharacterMinifigureCount();
    }
  }, [minifigureDetails]);

  const fetchMinifigureData = async () => {
    try {
      // Fetch ratings for graph
      const ratingResponse = await fetch(
        `${apiUrl}/getDailyRatings.php?minifigID=${searchValue}`
      );
      const ratingData = await ratingResponse.json();
      setRatingsData(ratingData);
    } catch (error) {
      console.error("Error fetching daily rating:", error);
    }

    /*try {
      const positionChangeResponse = await fetch(
        `${apiUrl}/getPositionChange.php?minifigID=${searchValue}`
      );
      const positionChangeData = await positionChangeResponse.json();
      setPositionChange(positionChangeData);
    } catch (error) {
      console.error("Error fetching position change:", error);
    }

    try {
      const positionChangeInThemeResponse = await fetch(
        `${apiUrl}/getPositionChangeInTheme.php?minifigID=${searchValue}&themeUrl=${themeUrl}`
      );
      const positionChangeInThemeData = await positionChangeInThemeResponse.json();
      setPositionChangeInTheme(positionChangeInThemeData);
    } catch (error) {
      console.error("Error fetching position change in theme:", error);
    }*/
      
      
      try {
        // Fetch the minifigure's position (both in total and within its theme)
        const positionResponse = await fetch(
          `${apiUrl}/getMinifigurePosition.php?minifigID=${searchValue}&themeUrl=${themeUrl}`
        );
        const positionData = await positionResponse.json();
        setPosition(positionData.position);
        setPositionInTheme(positionData.positionWithinTheme);
      } catch (error) {
        console.error("Error fetching minifigure positions:", error);
      }

      try {
        // Fetch the minifigure's position history
        const positionHistoryResponse = await fetch(
          `${apiUrl}/getPositionHistory.php?minifigID=${searchValue}&themeUrl=${themeUrl}`
        );
        const positionHistoryData = await positionHistoryResponse.json();
        setPositionHistory(positionHistoryData);
      } catch (error) {
        console.error("Error fetching minifigure position history:", error);
      }

      try {
        // Fetch the minifigure's win/loss statistics
        const statsResponse = await fetch(
          `${apiUrl}/getMinifigureStats.php?minifigID=${searchValue}`
        );
        const statsData = await statsResponse.json();
        setWinLossStats(statsData);

      } catch (error) {
        console.error("Error fetching win and loss statistics:", error);
      }

      
    };

    useEffect(() => {
      fetchMinifigureData();
    }, [searchValue]); // Call the effect whenever the minifigID changes

  // Access the values returned by getPositionChange script
  //const currentPosition = positionChange.currentPosition;
  //const previousPosition = positionChange.previousPosition;
  //const positionWeekAgo = positionChange.positionWeekAgo;
  const dailyChange = positionChange.currentPosition - positionChange.previousPosition;
  const weeklyChange = positionChange.currentPosition - positionChange.positionWeekAgo;

  // Access the values returned by getPositionChange script
  //const currentPositionInTheme = positionChangeInTheme.currentPosition;
  //const previousPositionInTheme = positionChangeInTheme.previousPosition;
  //const positionWeekAgoInTheme = positionChangeInTheme.positionWeekAgo;
  const dailyChangeInTheme = positionChangeInTheme.currentPosition - positionChangeInTheme.previousPosition;

  const percentageInTop100 = (positionHistory.totalDaysInTop100 / positionHistory.totalDays) * 100;
  const percentageInTop250 = (positionHistory.totalDaysInTop250 / positionHistory.totalDays) * 100;
  const percentageInTop500 = (positionHistory.totalDaysInTop500 / positionHistory.totalDays) * 100;
  const percentageOutsideTop500 = (positionHistory.totalDaysOutsideTop500 / positionHistory.totalDays) * 100;
  const averagePosition = parseInt(positionHistory.sumOfPositions) / parseInt(positionHistory.totalDays);

  const totalMatchUps = winLossStats.totalWins + winLossStats.totalLosses;
  const winPercentage = totalMatchUps > 0 ? (winLossStats.totalWins / totalMatchUps) * 100 : 0;
  const lossPercentage = totalMatchUps > 0 ? (winLossStats.totalLosses / totalMatchUps) * 100 : 0;

  const weeklyRatingChange = minifigureDetails.rating - ratingData.ratingOneWeekAgo;
  
  if(!fetchError) {
  return (
    <div className="flex flex-col justify-center items-center p-2 md:p-6">

      <div className="p-3">
        <h2 className="text-center font-bold text-xl md:text-2xl">{minifigureDetails.character_name} ({searchValue})</h2>
      </div>
      
      <div className="sm:max-md:w-5/6 xl:container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-9 md:grid-rows-7 lg:grid-rows-4 xl:grid-rows-5 gap-2">

        <div className="border border-gray-400 border-opacity-20 rounded-lg flex flex-col gap-2 justify-center items-center row-span-2 md:max-lg:row-span-3 lg:row-span-2 xl:row-span-3 xs:max-md:order-1 md:max-lg:order-1">
          <img className="h-60 md:h-80 lg:h-96 object-contain" src={`${minifigureImagePath}/${searchValue}.png`} alt={searchValue}/>
          <div className="bg-neutral-900 w-full flex-grow flex gap-12 justify-center items-center p-5 rounded-b-lg">
              <div className="flex-1 flex flex-col justify-end items-center">
                <div>{Math.round(ratingData.maxRating)}</div>
                <div className="text-sm">Max</div>
              </div>
              <div className="flex-1 flex flex-col justify-end items-center">
                <div className="text-2xl font-bold">{typeof minifigureDetails?.rating === "number" ? Math.round(minifigureDetails.rating) : "Loading..."}</div>
                <div className="text-sm">Rating</div>
              </div>
              <div className="flex-1 flex flex-col justify-end items-center">
              <div
                className={`${
                  weeklyRatingChange > 0
                    ? "text-green-400"
                    : weeklyRatingChange < 0
                    ? "text-red-400"
                    : "text-gray-400"
                }`}
              >
                {weeklyRatingChange > 0
                  ? `+${Math.round(weeklyRatingChange)}`
                  : weeklyRatingChange < 0
                  ? `-${Math.round(Math.abs(weeklyRatingChange))}`
                  : Math.round(weeklyRatingChange)}
              </div>
                <div className="text-sm">7d +/-</div>
              </div>
            </div>
        </div>

        <div className="bg-neutral-900 border border-gray-400 border-opacity-20 rounded-lg flex flex-col xs:max-md:order-3 md:max-lg:order-4 row-span-1">{/*40*/}
          <div className="w-full bg-black rounded-t-lg">
              <h2 className="py-2 px-4 font-bold">Overall Position</h2>
          </div>

          <div className="w-full px-4 py-2 flex justify-center items-center flex-grow">
                <div className="flex gap-10 sm:gap-16 md:gap-10 xl:gap-16">
                  <div className="flex flex-col justify-center items-center">
                    <h3 className="text-green-400 text-lg font-bold">Current</h3>
                      <div className="flex justify-center items-center min-h-8 min-w-8">
                      {position === 1 ?
                        <OverallFirstPositionMedal/>
                      : position === 2 ?
                        <OverallSecondPositionMedal/>
                      : position === 3 ?
                        <OverallThirdPositionMedal/>
                      : position}
                      </div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <h3 className="text-green-400 text-lg font-bold">7d</h3>
                    <div className="flex justify-center items-center min-h-8 min-w-8">{positionHistory.positionWeekAgo ? positionHistory.positionWeekAgo : <p>-</p>}</div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <h3 className="text-green-400 text-lg font-bold">30d</h3>
                    <div className="flex justify-center items-center min-h-8 min-w-8">{positionHistory.positionMonthAgo ? positionHistory.positionMonthAgo : <p>-</p>}</div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <h3 className="text-green-400 text-lg font-bold">90d</h3>
                    <div className="flex justify-center items-center min-h-8 min-w-8">{positionHistory.positionThreeMonthsAgo ? positionHistory.positionThreeMonthsAgo : <p>-</p>}</div>
                  </div>
                </div>
          </div>
        </div>

        <div className="bg-neutral-900 border border-gray-400 border-opacity-20 w-full rounded-lg row-span-2 flex flex-col xs:max-md:order-5 md:max-lg:order-3">{/*md:max-lg:order-3*/}
          <div className="w-full bg-black rounded-t-lg">
              <h2 className="py-2 px-4 font-bold">Position Performance</h2>
          </div>

          <div className="bg-neutral-900 rounded-b-lg flex-grow flex flex-col justify-center items-between gap-10">
            <div className="flex flex-col gap-3 md:px-3">

              <div className="flex gap-2 w-full px-1">
                <div className="w-1/5 md:w-2/5 xl:w-1/5 text-end text-sm">% in Top 100</div>
                <div className="w-3/5 md:w-2/5 xl:w-3/5 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-fuchsia-400 to-purple-400 h-6 w-full flex">
                    <div style={{ width: `${percentageInTop100}%`}} className="bg-transparent h-full"></div>
                    <div className="flex-1 bg-neutral-900 h-full"></div>
                  </div>
                </div>
                <div className="w-1/5 xl:w-1/5">{Math.round(percentageInTop100)}%</div>
              </div>

              <div className="flex gap-2 w-full px-1">
                <div className="w-1/5 md:w-2/5 xl:w-1/5 text-end text-sm">% in Top 250</div>
                <div className="w-3/5 md:w-2/5 xl:w-3/5 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-sky-400 to-blue-400 h-6 w-full flex">
                    <div style={{ width: `${percentageInTop250}%`}} className="bg-transparent h-full "></div>
                    <div className="flex-1 bg-neutral-900 h-full"></div>
                  </div>
                </div>
                <div className="w-1/5 xl:w-1/5">{Math.round(percentageInTop250)}%</div>
              </div>

              <div className="flex gap-2 w-full xl:px-1">
                <div className="w-1/5 md:w-2/5 xl:w-1/5 text-end text-sm">% in Top 500</div>
                <div className="w-3/5 md:w-2/5 xl:w-3/5 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-lime-400 to-green-400 h-6 w-full flex">
                    <div style={{ width: `${percentageInTop500}%`}} className="bg-transparent h-full"></div>
                    <div className="flex-1 bg-neutral-900 h-full"></div>
                  </div>
                </div>
                <div className="w-1/5 xl:w-1/5">{Math.round(percentageInTop500)}%</div>
              </div>

              <div className="flex gap-2 w-full px-1">
                <div className="w-1/5 md:w-2/5 xl:w-1/5 text-end text-sm">% Outside Top 500</div>
                <div className="w-3/5 md:w-2/5 xl:w-3/5 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-yellow-400 to-amber-400 h-6 w-full flex">
                    <div style={{ width: `${percentageOutsideTop500}%`}} className="bg-transparent h-full"></div>
                    <div className="flex-1 bg-neutral-900 h-full"></div>
                  </div>
                </div>
                <div className="w-1/5 xl:w-1/5">{Math.round(percentageOutsideTop500)}%</div>
              </div>

            </div>

            <div className="flex md:flex-row lg:flex-col xl:flex-row justify-center items-center gap-6 xl:gap-20">
              <div className="flex flex-col justify-center items-center"><p className="text-lg text-green-400 font-bold">{Math.round(averagePosition)}</p><h3>Average Position</h3></div>
              <div className="flex flex-col justify-center items-center"><p className="text-lg text-yellow-500 font-bold">{positionHistory.topPosition}</p><h3>Top Position</h3></div>
            </div>
          </div>
        </div>

        <div className="bg-neutral-900 border border-gray-400 border-opacity-20 w-full rounded-lg flex flex-col xs:max-md:order-4 md:max-lg:order-5 row-span-1"> {/* By making this outer div a flex container and then adding flex grow to the outer div containing the information, that div will take up the space that is left after the space used by the heading div */}
          <div className="w-full bg-black rounded-t-lg">
            <h2 className="py-2 px-4 font-bold">Position in Theme ({minifigureDetails.theme})</h2>
          </div>

          <div className="w-full px-4 py-2 flex justify-center items-center flex-grow"> {/* Add flex grow to make this div use up the space left after the heading div */}
                <div className="flex gap-10 sm:gap-16 md:gap-10 xl:gap-16">
                  <div className="flex flex-col justify-center items-center">
                    <h3 className="text-green-400 text-lg font-bold">Current</h3>
                      <div className="flex justify-center items-center min-h-8 min-w-8">
                      {positionInTheme === 1 ?
                        <ThemeFirstPositionMedal />
                      : positionInTheme === 2 ?
                        <ThemeSecondPositionMedal />
                      : positionInTheme === 3 ?
                        <ThemeThirdPositionMedal />
                      : positionInTheme}
                      </div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <h3 className="text-green-400 text-lg font-bold">7d</h3>
                    <div className="flex justify-center items-center min-h-8 min-w-8">{positionHistory.positionWeekAgoInTheme ? positionHistory.positionWeekAgoInTheme : <p>-</p>}</div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <h3 className="text-green-400 text-lg font-bold">30d</h3>
                    <div className="flex justify-center items-center min-h-8 min-w-8">{positionHistory.positionMonthAgoInTheme ? positionHistory.positionMonthAgoInTheme : <p>-</p>}</div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <h3 className="text-green-400 text-lg font-bold">90d</h3>
                    <div className="flex justify-center items-center min-h-8 min-w-8">{positionHistory.positionThreeMonthsAgoInTheme ? positionHistory.positionThreeMonthsAgoInTheme : <p>-</p>}</div>
                  </div>
                </div>
          </div>
        </div>

        <div className="bg-neutral-900 border border-gray-400 border-opacity-20 rounded-lg md:col-span-2 md:row-span-2 lg:row-span-3 xs:max-md:order-6 md:max-lg:order-6">{/*md:max-lg:order-6*/}
          <div className="w-full bg-black rounded-t-lg">
            <h2 className="py-2 px-4 font-bold">Rating History</h2>
          </div>
        <RatingsChart chartData={ratingsData}/>
        </div>

        <div className="bg-neutral-900 border border-gray-400 border-opacity-20 rounded-lg flex flex-col xs:max-md:order-2 md:max-lg:order-2 max-h-[180px]"> {/*md:max-lg:order-2*/}
          <div className="w-full bg-black rounded-t-lg">
            <h2 className="py-2 px-4 font-bold">Match Up Record</h2>
          </div>

          <div className="flex flex-col gap-10 md:gap-4 py-4 flex-grow">
            <div className="flex flex-col gap-1">
              <div className="flex gap-2 w-full px-1">
                <div className="w-2/6 text-end">{winLossStats.totalWins} Won</div>
                <div className="w-5/6 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-lime-400 to-green-400 h-6 w-full rounded-r-lg flex">
                    <div style={{ width: `${winPercentage}%`}} className="bg-transparent h-full rounded-r-lg"></div>
                    <div className="flex-1 bg-neutral-900 h-full"></div>
                  </div>
                </div>
              </div>

              <div className="flex gap-2 w-full px-1">
                <div className="w-2/6 text-end">{winLossStats.totalLosses} Lost</div>
                <div className="w-5/6 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-orange-400 to-red-400 h-6 w-full rounded-r-lg flex">
                    <div style={{ width: `${lossPercentage}%`}} className="bg-transparent h-full rounded-r-lg"></div>
                    <div className="flex-1 bg-neutral-900 h-full"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center gap-5 lg:gap-6">
              <div className="flex md:flex-row lg:flex-col xl:flex-row justify-center items-center xl:items-end gap-1 lg:gap-2">
                <div className="md:text-lg lg:text-xl font-bold text-green-400">{winPercentage.toFixed(2)}%</div>
                <div>Win Percentage</div>
              </div>
              <div className="flex md:flex-row lg:flex-col xl:flex-row justify-center items-center xl:items-end gap-1 lg:gap-2">
                <div className="md:text-lg lg:text-xl font-bold text-green-400">{totalMatchUps}</div>
                <div>Match Ups</div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-neutral-900 border border-gray-400 border-opacity-20 md:max-lg:col-span-2 rounded-lg xs:max-md:order-7 md:max-lg:order-7 max-h-[180px]">{/*md:max-lg:order-7*/}
          <div className="w-full bg-black rounded-t-lg">
            <h2 className="py-2 px-4 font-bold">Info</h2>
          </div>

          <div className="flex justify-center items-center my-5 gap-4 md:gap-12 lg:gap-8 lg:p-3">
            <div className="flex flex-col justify-start items-center gap-2">
              <p className="text-center">No. of {minifigureDetails.character_name} minifigures:</p><Link className="text-xl font-bold text-center text-green-500 hover:text-green-400" to={`/results/minifigures/${minifigureDetails.character_name}`}>{characterMinifigureCount}</Link>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex gap-3 md:gap-6">
                <img className="object-contain" src={'/brickranker-logo-small.webp'} alt='bricklink logo' width={30} height={30} quality={100}/>
                <Link href={`${brickrankerURL}/${searchValue}`} className="inline-block text-center text-black md:font-bold bg-green-500 p-1 rounded-md">View Value</Link>
              </div>
              <div className="flex gap-3 md:gap-6">
                <img className="object-contain" src={'/bricklink-logo-small.png'} alt='brickranker logo' width={30} height={30} quality={100}/>
                <Link href={`https://www.bricklink.com/v2/catalog/catalogitem.page?M=${searchValue}#T=S&O={%22iconly%22:0}`} className="inline-block text-center text-black md:font-bold bg-green-500 p-1 rounded-md">Buy Now</Link>
              </div>
            </div>

          </div>
        </div>

        {minifigureDetails.description && (
          <div className="bg-neutral-900 border border-gray-400 border-opacity-20 col-span-1 md:col-span-2 lg:col-span-3 rounded-lg order-8">
            <div className="w-full bg-black rounded-t-lg">
              <h2 className="py-2 px-4 font-bold">Info</h2>
            </div>
            <div class="flex flex-col lg:flex-row gap-4 lg:gap-12 px-4">
              <div className="">
                <h1 className="text-xl font-bold py-2">Description</h1>
                <p className="text-justify">{minifigureDetails.description}</p>
              </div>
              <div class="flex gap-6 sm:gap-20 justify-center items-center">
                <div className="basis-1/6 text-center py-2">
                  <h1 className="text-lg md:text-xl font-bold py-2">Production Quantity</h1>
                  <p className="text-2xl md:text-3xl font-bold text-green-500">{minifigureDetails.production_qty}</p>
                </div>
                <div className="basis-1/6 text-center">
                  <h1 className="text-lg md:text-xl font-bold py-2">Geographical Release</h1>
                  <p className="text-2xl md:text-3xl font-bold text-green-500">{minifigureDetails.geographical_release}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

        


        
    </div>
  );
  }
  else {
    return (
      <div className="flex justify-center items-center p-10">
        <div className="container flex flex-col gap-5 justify-center items-center">
          <h1 className="text-xl">Oh no!</h1>
          <p className="leading-6">The LEGO character with the code <span className="font-bold">{searchValue}</span> can't be found in the database. This is either because it doesn't exist or is a variant of another minifigure. It might also be that the LEGO character you are thinking of does not contain at least two of the three standard minfigure parts: head, torso and legs. Any LEGO character that doesn't include at least two of those parts isn't officially classed as a minifigure. </p>
          <p>Please try searching for a different minfigure.</p>
        </div>
      </div>
    )
  }
}
 
export default MinifigurePage;